import React, { FunctionComponent, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { TableHead, TableRow, TableCell } from "@material-ui/core";
import clsx from "clsx";
import TableHeaderItem from "./TableHeaderItem";
import { SortFields, SortOrder } from "model/Client";

interface Props {
  onSort: (field: SortFields | undefined, order: SortOrder) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      paddingLeft: "0px",
      paddingTop: "0px",
      paddingRight: "0px",
      borderBottom: "1px solid #F1F1F1",
      borderTop: "1px solid #F1F1F1",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "5px",
        paddingTop: "5px",
        fontSize: "5px",
      },
      [theme.breakpoints.only("md")]: {
        paddingBottom: "8px",
        paddingTop: "8px",
        fontSize: "8px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingBottom: "11px",
        paddingTop: "11px",
        fontSize: "11px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingBottom: "17px",
        paddingTop: "17px",
        fontSize: "16px",
      },
    },
    businessName: {
      width: "25%",
      "&:hover": {
        cursor: "pointer",
        color: "#5E8677"
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "27px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "43px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "57px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "87px",
      },
    },
    businessId: {
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "27px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "43px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "57px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "87px",
      },
    },
    category: {
      width: "10%",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "2px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "3px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "6px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "7px",
      },
    },
    locations: {
      textAlign: "center",
      width: "12%",
    },
    users: {
      textAlign: "center",
      width: "8%",
    },
    terminals: {
      width: "8%",
      textAlign: "center",
      "&:hover": {
        cursor: "pointer",
        color: "#5E8677"
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "2px",
      },
      [theme.breakpoints.only("md")]: {
        paddingLeft: "3px",
      },
      [theme.breakpoints.only("lg")]: {
        paddingLeft: "6px",
      },
      [theme.breakpoints.only("xl")]: {
        paddingLeft: "7px",
      },
    },
    status: {
      width: "15%",
      textAlign: "center",
    },
    actions: {
      width: "11%",
    }
  })
);

export const TableHeader: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const [sortField, setSortField] = useState<SortFields | undefined>(undefined);
  const [sortOrder, setSortOrder] = useState<SortOrder | undefined>(undefined);

  const sortHandler = (field: SortFields | undefined, order: SortOrder) => {
    setSortField(field);
    setSortOrder(order);
    props.onSort(field, order);
  };

  return (
    <TableHead>
      <TableRow>
      <TableCell className={clsx(classes.cell, classes.businessId)}>
          Business ID
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.businessName)}>
          <TableHeaderItem
            label="Business Name"
            sortField={SortFields.BUSINESS_NAME}
            sortOrder={sortField === SortFields.BUSINESS_NAME ? sortOrder : undefined}
            onSort={sortHandler}
          />
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.category)}>
          Category
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.locations)}>
          Locations
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.users)}>
          Users
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.terminals)}>
          <TableHeaderItem
            label="POS Terminals"
            sortField={SortFields.TERMINALS}
            sortOrder={sortField === SortFields.TERMINALS ? sortOrder : undefined}
            center={true}
            onSort={sortHandler}
          />
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.status)}>
          Status
        </TableCell>
        <TableCell className={clsx(classes.cell, classes.actions)}></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
